import dayjs from 'dayjs';
import { get } from 'lodash';
import { getCurrency } from '@fxtr/i18n';
import { CollectionDeliveryTypes } from '$util/types';
import type { AnyObject } from '$util/types';
// eslint-disable-next-line import/no-cycle
import type { ProductsAddedToBasketAnalytics, ProductsListAnalytics } from './types';
import { Dimensions } from './dimensions';

export const getDaysTo = (time: string): number | undefined => {
  if (!time) return undefined;
  return dayjs(time).diff(dayjs(), 'days');
};

export const getDaysFrom = (time: string): number | undefined => {
  if (!time) return undefined;
  return dayjs().diff(dayjs(time), 'days');
};

export const year = (time: string): number | undefined => {
  if (!time) return undefined;
  return dayjs(time).year();
};

export const getCost = (price: number): string => (price / 100).toFixed(2);
export const getInt = (val: string): number => parseInt(val, 10);

export const getFloat = (val: string): string | undefined => {
  if (!val) return undefined;
  return parseFloat(val).toFixed(1);
};

/**
 * These steps are required for setting up Checkout Behavior Analysis in GA.
 * https://analytics.google.com/analytics/web/#/report/conversions-ecommerce-checkout-behavior/a99816388w146581114p154265141/_u.date00=20220501&_u.date01=20220524&checkout_analysis-funnelTable.funnelStageIndex=0
 *
 * @todo Lucie confirmed that we should refactor to make the number correlating to the actual number in the UI after FR launch.
 */
export enum CheckoutStep {
  DASHBOARD = 1, // Do we still need it?
  QUOTES = 2,
  SCHEDULE = 3,
  PERSONAL_DETAILS = 4,
  BUNDLE = 5,
  PAYMENT = 6,
  SUCCESS = 7,
}

export const convertCollectionDeliveryType = (type: CollectionDeliveryTypes): string => {
  switch (type) {
    case CollectionDeliveryTypes.OUT_OF_COVERAGE:
      return CollectionDeliveryTypes.OUT_OF_COVERAGE;
    case CollectionDeliveryTypes.GARAGE:
      return CollectionDeliveryTypes.GARAGE;
    case CollectionDeliveryTypes.CUSTOMER:
      return CollectionDeliveryTypes.CUSTOMER;
    default:
      return CollectionDeliveryTypes.FIXTER;
  }
};

export const getHoursUntilNextSlot = (timetable: AnyObject): number => {
  const availableDay = get(timetable, 'collection.slotRules', []).find((date: AnyObject) => date.available);
  const allSlots: AnyObject[] = Object.values(get(availableDay, 'slots', {}));
  const availableSlotHour = allSlots
    .sort((a, b) => (a.hour > b.hour ? 1 : -1))
    .find((slot) => slot.availability !== 'full')?.hour;

  const fullDate = dayjs(`${get(availableDay, 'date', '')} ${availableSlotHour}`);
  const hoursUntilNextSlot = dayjs(fullDate).diff(dayjs().format(), 'hour') || 0;
  return hoursUntilNextSlot;
};

export const basketProductMetrics = (
  productAnalyticsData: Record<string, any>
): ProductsAddedToBasketAnalytics => ({
  id: get(productAnalyticsData, 'sku'),
  name: get(productAnalyticsData, 'name'),
  event_category: get(productAnalyticsData, 'categories'),
  variant: get(productAnalyticsData, 'priceId'), // can we rename to priceId?
  position: get(productAnalyticsData, 'listPosition'),
  price: getCost(get(productAnalyticsData, 'price')),
  quantity: 1,
  currency: getCurrency(get(productAnalyticsData, 'segment')),
  value: Number(getCost(get(productAnalyticsData, 'price'))),
  items: [
    {
      item_id: get(productAnalyticsData, 'sku'),
      item_name: get(productAnalyticsData, 'name'),
      price: Number(getCost(get(productAnalyticsData, 'price'))),
    },
  ],
  component: get(productAnalyticsData, 'component'),
  [Dimensions.DIMENSION_MAKE]: get(productAnalyticsData, 'make'),
  [Dimensions.DIMENSION_FUEL_TYPE]: get(productAnalyticsData, 'fuelType'),
  [Dimensions.METRIC_ENGINE_SIZE]: getFloat(get(productAnalyticsData, 'litre')),
  [Dimensions.METRIC_VEHICLE_AGE]: get(productAnalyticsData, 'vehicleAge'),
  [Dimensions.METRIC_DAYS_TO_MOT]: getDaysTo(get(productAnalyticsData, 'nextMotDate')),
  [Dimensions.DIMENSION_POSTCODE_LANDING]: get(productAnalyticsData, 'postcode'),
  [Dimensions.METRIC_MARKET_PRICE]: getCost(get(productAnalyticsData, 'marketPrice')),
});

export const productToMetrics = (productAnalyticsData: Record<string, any>): ProductsListAnalytics => ({
  id: get(productAnalyticsData, 'sku'),
  name: get(productAnalyticsData, 'name'),
  event_category: get(productAnalyticsData, 'categories'),
  variant: get(productAnalyticsData, 'priceId'), // can we rename to priceId?
  list: 'quotes-page',
  position: get(productAnalyticsData, 'listPosition'),
  price: getCost(get(productAnalyticsData, 'price')),
  [Dimensions.DIMENSION_MAKE]: get(productAnalyticsData, 'make'),
  [Dimensions.DIMENSION_FUEL_TYPE]: get(productAnalyticsData, 'fuelType'),
  [Dimensions.METRIC_ENGINE_SIZE]: getFloat(get(productAnalyticsData, 'litre')),
  [Dimensions.METRIC_VEHICLE_AGE]: get(productAnalyticsData, 'vehicleAge'),
  [Dimensions.METRIC_DAYS_TO_MOT]: getDaysTo(get(productAnalyticsData, 'nextMotDate')),
  [Dimensions.METRIC_LAST_KNOWN_MILEAGE]: getInt(get(productAnalyticsData, 'lastEstimatedMiles')),
  [Dimensions.METRIC_MARKET_PRICE]: getCost(get(productAnalyticsData, 'marketPrice')),
});
