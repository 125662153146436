/* eslint-disable */
import Cookies from 'js-cookie';
import config from 'config';
import { cookieDomain } from './cookieOptions';
import { isFunctionalCategoryActive } from './oneTrust';

const COOKIES_OPTIONS = {
  domain: cookieDomain,
  secure: config.get('public.cookie.secure'),
  expires: 7,
};

const TRACK_BOOKING_COOKIE = 'trackBooking';
const UTMs = ['term', 'campaign', 'source', 'medium', 'device'];

const valueTrack = [
  'campaignid',
  'adgroupid',
  'feeditemid',
  'targetid',
  'loc_interest_ms',
  'loc_physical_ms',
  'matchtype',
  'network',
  'device',
  'devicemodel',
  'gclid',
  'creative',
  'keyword',
  'placement',
  'target',
  'param1',
  'param2',
  'random',
  'adposition',
  'ignore',
];

const isCookie = (cookieName) => Cookies.get(cookieName) != null && Cookies.get(cookieName) != '';

const getUrlParameter = (param) => {
  const loc = location.search.substr(1);
  if (!loc) return '';

  const params = loc.split('&');
  for (let i = 0; i < params.length; i++) {
    const p = params[i].split('=');
    if (p[0] === param) return decodeURIComponent(p[1]);
  }
  return '';
};

export default function trackBooking() {
  if (!isFunctionalCategoryActive()) return;

  const url = location.href;
  const partner = isCookie('fixterPartner') ? Cookies.get('fixterPartner') : location.pathname;
  const campaign = getUrlParameter('coupon');
  const referrer = document.referrer;

  const UTM_PARAMS = UTMs.reduce((tot, utm) => {
    const paramValue = getUrlParameter(`utm_${utm}`);

    if (paramValue) tot[`utm_${utm}`] = getUrlParameter(`utm_${utm}`);
    return tot;
  }, {});

  const VALUE_TRACK_PARAMS = valueTrack.reduce((tot, vtParam) => {
    const paramValue = getUrlParameter(`${vtParam}`);

    if (paramValue) tot[`${vtParam}`] = getUrlParameter(`${vtParam}`);
    return tot;
  }, {});

  const createBookingTrackData = () => ({
    landingPage: url,
    partner,
    campaign,
    referrer,
    ...UTM_PARAMS,
    ...VALUE_TRACK_PARAMS,
  });

  const trackBookingCookie = isCookie(TRACK_BOOKING_COOKIE)
    ? Cookies.getJSON(TRACK_BOOKING_COOKIE)
    : createBookingTrackData();

  Cookies.set(TRACK_BOOKING_COOKIE, trackBookingCookie, COOKIES_OPTIONS);
}
