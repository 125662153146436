import config from 'config';
import { getClientSideCookieDomain } from './domains';
import { isBrowser } from './index';

/**
 * @todo - shouldn't fallback to unknown-domain here since it will lead to issues though will
 * require a bit of a refactor
 */
export const cookieDomain: string = isBrowser ? getClientSideCookieDomain() : 'unknown-domain';
const cookieSecure: boolean = config.get('public.cookie.secure');

/**
 * !!!IMPORTANT!!!
 * If any cookie is needed to be read server-side it has to be whitelisted
 * for forward in terraform `cf.ts` for `default_cache_behavior`.
 *
 * @deprecated use App Router `getCookieOptions()` instead.
 */
export const cookieOptions = {
  fixterSessionId: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  fixterAnalyticsSessionId: {
    secure: cookieSecure,
    httpOnly: false,
    // analytics session cookie
  },
  kameleoonVisitorCode: {
    secure: cookieSecure,
    httpOnly: false,
    // Kameleoon tracking cookie
  },
  fixterAccessToken: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 30, // days
  },
  fixterAdminLogin: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 0.5, // days
  },
  customer: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 30, // days
  },
  fixterLanding: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 7, // days
  },
  fixterHome: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 7, // days
  },
  trackBooking: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 7, // days
  },
  fixterPartner: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 1, // days
  },
  acquisitionMethod: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 1, // days
  },
  fixterVrm: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  fixterPostcode: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  EnableMotServiceBundle: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  fixterEmailCampaign: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 7, // days
  },
  fixterMarketingCampaign: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 7, // days
  },
  fixterMembershipGarage: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
  },
  fixterSegment: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 7, // days
  },
  collectionType: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  monMecRedirect: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  activeCampaignData: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
  },
  shouldTriggerSaturdayBookings: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  shouldTriggerLiquidCrossSellFlow: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    // session cookie
  },
  awin: {
    domain: cookieDomain,
    secure: cookieSecure,
    httpOnly: false,
    expires: 30,
  },
};

/**
 * Cookies that will be automatically removed when landing on an error page.
 */
export const COOKIES_TO_RESET: ReadonlyArray<keyof typeof cookieOptions> = Object.freeze([
  'fixterSessionId',
  'fixterAccessToken',
  'fixterLanding',
  'fixterHome',
  'fixterVrm',
  'fixterPartner',
  'acquisitionMethod',
  'trackBooking',
  'customer',
]);

export const getAbsoluteExpiry = (daysTillExpiry: number) => new Date(Date.now() + daysTillExpiry);
