import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { get, pick } from 'lodash';
import config from 'config';
import type { ApolloErrorException } from '$apollo/util';

interface GqlOptions<TVariables> {
  readonly variables?: TVariables;
}

export interface GqlResponse<TData> {
  readonly data: TData;
  readonly errors?: ApolloErrorException[];
}

/**
 * Make GraphQL requests without Apollo
 */
export const fetchGQL = <TData, TVariables>(
  query: string,
  options: GqlOptions<TVariables> = {},
  reqConfig: AxiosRequestConfig = {}
): Promise<GqlResponse<TData>> =>
  axios
    .post<
      GqlResponse<TData>
    >(config.get('public.graph_api'), { query, ...options }, { withCredentials: true, ...reqConfig })
    .then((res) => {
      if (!res || !res.data) throw new Error('Failed to fetch graphql');
      const gqlRes = res.data;
      if (
        Array.isArray(gqlRes.errors) &&
        !gqlRes.errors.every((err) => get(err, 'info.clientExpectedErrorCode'))
      ) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw gqlRes.errors;
      }
      return pick(gqlRes, ['data', 'errors']);
    });
