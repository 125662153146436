import { gql } from '@apollo/client';

export interface Campaign {
  readonly campaignId: string;
  readonly code: string;
  readonly name: string;
  readonly discountAmountPence?: number;
  readonly discountAmountPercentage?: number;
  readonly affectedItems?: string[];
}
export interface CampaignWithSource extends Campaign {
  source: 'url' | 'cookie' | 'global';
}

export interface QueryGetCampaignById {
  readonly getCampaignById: Campaign | null;
}

export const QUERY_GET_CAMPAIGN_BY_ID_GQL = gql`
  query getCampaignById($campaignId: String!) {
    getCampaignById(campaignId: $campaignId) {
      campaignId
      code
      name
      discountAmountPence
      discountAmountPercentage
      affectedItems
    }
  }
`;

export interface QueryGetCampaignByIdVariables {
  readonly campaignId: string;
}

export const QUERY_GET_CAMPAIGN_BY_ID = `
  query getCampaignById($campaignId: String!) {
    getCampaignById(campaignId: $campaignId) {
      campaignId
      code
      name
      discountAmountPence
      discountAmountPercentage
      affectedItems
    }
  }
`;
