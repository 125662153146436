/**
 * Existing Fixter partners: https://docs.google.com/spreadsheets/d/13Yfsn-HEChAR1SSmFXkFJQ4_E7LABXArpKo2Nvsdkn4/edit#gid=0
 */

const Partners = [
  {
    name: 'Esure',
    slug: 'esure',
    campaignId: '46c93621-78ac-42dc-9d54-8d4183c2dd2f',
    prices: {
      mot: '£19',
    },
  },
  {
    name: 'carAnalytics',
    slug: 'caranalytics',
    campaignId: '914dd5c7-76b2-4939-b190-66a4e2de3dc7',
    prices: {
      mot: '£34',
    },
  },
  {
    name: "Sheilas' Wheels",
    slug: 'sheilas-wheels',
    campaignId: '2a8e6665-ca76-4cb3-b74a-215d5f3240d5',
    prices: {
      mot: '£19',
    },
  },
  {
    name: 'Turo',
    slug: 'turo',
    campaignId: '0844146a-7790-456a-8b78-ac39d8868982',
    prices: {
      mot: '£34',
    },
  },
  {
    name: 'JustPark',
    slug: 'justpark',
    campaignId: 'bd2ccb03-15ac-4f5e-b3a3-abbaeb914a29',
    prices: {
      mot: '£34',
    },
  },
  {
    name: 'Perkbox',
    slug: 'perkbox',
    campaignId: '611ef3e4-d375-468d-ae8c-2876cedfdefa',
  },
  {
    name: 'Getaround',
    slug: 'getaround',
    campaignId: '611ef3e4-d375-468d-ae8c-2876cedfdeea',
  },
  {
    name: 'CarTaxCheck',
    slug: 'cartaxcheck',
    campaignId: 'efea6499-3e60-430c-847c-c98b3ac383d6',
    prices: {
      mot: '£34',
    },
  },
  {
    name: 'Kapten',
    slug: 'kapten',
  },
  {
    name: 'Boundless',
    slug: 'boundless',
  },
  {
    name: 'Parliament Hill',
    slug: 'parliament-hill',
  },
  {
    name: 'UNiDAYS',
    slug: 'unidays',
  },
  {
    name: 'SpecialistVehicle',
    slug: 'specialistvehicle',
    campaignId: '4d80141e-c853-4ada-b47d-3078bcb394cd',
  },
  {
    name: 'EngineerAftercare',
    slug: 'engineeraftercare',
    campaignId: '76a9a2a5-25bd-45c8-9442-c36f4b52d914',
  },
  {
    name: 'PerkboxService',
    slug: 'perkboxservice',
    campaignId: '73353623-82d2-4bd1-ac87-dc2d9c06fdb0',
  },
  {
    name: 'Best4Warranty',
    slug: 'best4warranty',
    campaignId: '785c116a-d04b-4c02-89d8-96c453472156',
  },
];
module.exports = Partners;
