import path from 'path';
import config from 'config';
import qs from 'qs';
import type { AppContext as AppContextType, AppInitialProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { getLocale, getSegmentFromDomain, Segment } from '@fxtr/i18n';
import { withRouter } from 'next/router';
import type { NextComponentType } from 'next/types';
import type { Extras } from '@sentry/types';
import * as Sentry from '@sentry/nextjs';
import nextCookies from 'next-cookies';
import autoParse from 'auto-parse';
import get from 'lodash/get';
import { resetId } from 'react-id-generator';
import dayjs from 'dayjs';
import { AppContext, useAppContext } from '$util/AppContext';
import trackBooking from '$util/trackBooking';
import setHomeCookies, {
  isExpectedMarketingUtmMedium,
  deleteFunctionalCookies,
  maybeResetMembershipGarageCookie,
} from '$util/setHomeCookies';
import type { AnyObject } from '$util/types';
import {
  sendPageViewWithDimensions,
  setConsentForOneTrustCategories,
} from '$util/analytics/googleAnalytics4';
import { getServerLogger, isBlog, isBrowser, isLocalhost, isWidget } from '$util/index';
import type { AppContextGlobal } from '$util/AppContextGlobal';
import { getSegmentFromLocale } from '$util/getSegment';
import { fetchGQL } from '$api/fetchGQL';
import { getDomainFromHostname, getDomainForLocale } from '$util/domains';
import { QUERY_GET_CAMPAIGN_BY_ID } from '$apollo/gql/campaign';
import type { QueryGetCampaignByIdVariables, QueryGetCampaignById } from '$apollo/gql/campaign';
import { internalAnalytics } from '$util/analytics/analyticsService/client';
import {
  isFunctionalCategoryActive,
  isPerformanceCategoryActive,
  isTargetingCategoryActive,
} from '$util/oneTrust';
import { useActiveExperiment } from '$util/abTest';
import { ActiveExperiments } from '$util/abTest/experiments';
import type { StageEnv } from '@/types';
import { context } from '@/contexts/GlobalContext';
import { SetGlobalContextValueClient } from '@/contexts/GlobalContext/SetContextValue/SetGlobalContextValueClient';
import { ga4Event } from '@/util/ga4Analytics';
import { getOneTrustScriptId } from '@/components/templates/ThirdPartyScripts/oneTrustDomainsList';
import { getGlobalCampaign } from '@/server/util/readPromoCampaign/getData';
import type { PromoCampaign } from '@/server/util/readPromoCampaign';

// START Global CSS
/**
 * @todo EXTERMINATE!!!
 * https://ant.design/docs/react/migration-v5
 */
import 'antd/dist/reset.css';

/**
 * @todo In App Router move global styles into layouts or their corresponding components.
 * @link https://nextjs.org/docs/app/building-your-application/styling/css-modules#global-styles
 */
import '$styles/global.scss';
import '@/components/molecules/NavigationMenu/global.scss';
// END Global CSS

/**
 * @todo dynamically import based on page segment.
 */
import 'dayjs/locale/fr';
import 'dayjs/locale/en-gb';

declare global {
  interface Window {
    initializeInternalScripts: () => void;
    handleGoogleConsent: () => void;
    oneTrustCallback: () => void;
  }
}

/**
 * @important this has to set the same global values as App Router until we migrate all pages.
 */
const initGlobalContextData = () => {
  const stageEnv = config.get<StageEnv>('public.environment') || 'development';
  const isSentryEnabled = config.get<boolean>('public.sentry.enabled');
  const isGAEnabled = config.get<boolean>('public.ga4.enabled');

  SetGlobalContextValueClient({
    data: {
      stageEnv,
      isSentryEnabled,
      isGAEnabled,
      'promoCampaignId()': null,
    },
  });
};

initGlobalContextData();

if (isBrowser && !isWidget() && !isBlog()) {
  window.initializeInternalScripts = () => {
    trackBooking();
    setHomeCookies();
    maybeResetMembershipGarageCookie();
  };

  window.handleGoogleConsent = () => {
    const analyticsConsent = (isPerformanceCategoryActive() && 'granted') || 'denied';
    const marketingConsent = (isTargetingCategoryActive() && 'granted') || 'denied';
    const functionalConsent = (isFunctionalCategoryActive() && 'granted') || 'denied';
    setConsentForOneTrustCategories(analyticsConsent, marketingConsent, functionalConsent);
    sendPageViewWithDimensions();
    ga4Event.ready = true;
    const consentSetEvent = new CustomEvent('consent-set');
    document.body.dispatchEvent(consentSetEvent);
  };

  window.oneTrustCallback = () => {
    if (!isFunctionalCategoryActive()) {
      deleteFunctionalCookies();
    }
  };
}

if (!isBrowser) {
  /**
   * @deprecated used for old pages with eval
   */
  // @ts-ignore-next-line
  global.SERVER_PATH = path.resolve('./server'); // use to access bindings in getInitialProps with eval
}

const withServerParamsPageProps = (pageProps: AppInitialProps['pageProps'], { ctx }: AppContextType) => {
  // @todo not use anymore, prefer using data from NextJs page directly instead of express
  const props = pageProps;
  props.serverParams = get(ctx, 'query', {});
  return props;
};

const withCookiesPageProps = (pageProps: AppInitialProps['pageProps'], { ctx }: AppContextType) => {
  const props = pageProps;
  props.cookies = autoParse(nextCookies(ctx));
  return props;
};

const withPageTemplatePageProps = (
  pageProps: AppInitialProps['pageProps'],
  component: NextComponentType
): AppInitialProps => {
  const props = pageProps;
  props.pageTemplate = {
    name: component.name,
  };
  return props;
};

const withLocationPageProps = (
  pageProps: AppInitialProps['pageProps'],
  { router, ctx }: AppContextType
): AppInitialProps => {
  const props = pageProps;
  props.locale = router.locale;
  if (!ctx.req?.url) {
    return props;
  }
  // We build the location object manually since `.toString` and `.toJSON` will convert this to a string
  // when passing the context to the client.
  const url = new URL(ctx.req.url, `${isLocalhost() ? 'http' : 'https'}://${ctx.req?.headers?.host}`);
  props.location = {
    hash: url.hash,
    host: url.host,
    hostname: url.hostname,
    href: url.href,
    origin: url.origin,
    password: url.password,
    pathname: url.pathname,
    port: url.port,
    protocol: url.protocol,
    search: url.search,
    searchParams: url.searchParams,
    username: url.username,
  };
  return props;
};

const getUtmMedium = (query: NodeJS.Dict<string | string[]>) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { utm_medium } = query;
  if (!isExpectedMarketingUtmMedium(utm_medium as string)) return null;
  return (utm_medium ?? null) as string | null;
};

const getUtmCampaignId = (query: NodeJS.Dict<string | string[]>) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { campaignId, utm_medium } = query;
  if (!isExpectedMarketingUtmMedium(utm_medium as string)) return null;
  return (campaignId ?? null) as string | null;
};

const getPromoCampaignId = (query: NodeJS.Dict<string | string[]>) => {
  const { campaignId, promo } = query;
  if (promo !== 'true') return null;
  return (campaignId ?? null) as string | null;
};

/**
 * @warn keep logic in sync with same function in `readPromoCampaign` from App Router
 */
const getCampaignIdInfo = async (
  props: AppInitialProps['pageProps'],
  query: NodeJS.Dict<string | string[]>
): Promise<Pick<PromoCampaign, 'campaignId' | 'source'> | null> => {
  const utmCampaignId = getUtmCampaignId(query);
  if (utmCampaignId) {
    return {
      campaignId: utmCampaignId,
      source: 'url',
    };
  }

  const cookieCampaignId = props.cookies.fixterMarketingCampaign;
  if (cookieCampaignId) {
    return {
      campaignId: cookieCampaignId,
      source: 'cookie',
    };
  }

  /**
   * Hide organic banners from paid traffic campaigns
   * @note paid traffic can be with or without a campaignId
   */
  const utmMedium = getUtmMedium(query);
  const cookieAcquisitionMethod = props.cookies.acquisitionMethod;
  const isPaidTraffic =
    utmMedium === 'cpc' ||
    cookieAcquisitionMethod === 'cpc' ||
    utmMedium === 'paid_social' ||
    cookieAcquisitionMethod === 'paid_social';
  if (isPaidTraffic) return null;

  const promoCampaignId = getPromoCampaignId(query);
  if (promoCampaignId) {
    return {
      campaignId: promoCampaignId,
      source: 'path',
    };
  }

  /**
   * @note On `readPromoCampaign` here we would have read the path campaign
   * but it's not needed on Page Router as it's intended only for long-tail pages.
   */

  const segment = getSegmentFromDomain(props.location.hostname);
  const globalCampaignId = await getGlobalCampaign(segment);
  if (globalCampaignId) {
    return {
      campaignId: globalCampaignId,
      source: 'global',
    };
  }

  return null;
};

/**
 * @see `readPromoCampaign()` on App Router
 */
const withCampaignPageProps = async (
  props: AppInitialProps['pageProps'],
  { router: { query } }: AppContextType
): Promise<AppInitialProps> => {
  context.setRequestValue('promoCampaignId()', () => null);

  try {
    const campaignIdInfo = await getCampaignIdInfo(props, query);
    if (!campaignIdInfo) return props;
    const { campaignId, source } = campaignIdInfo;

    const campaign = await fetchGQL<QueryGetCampaignById, QueryGetCampaignByIdVariables>(
      QUERY_GET_CAMPAIGN_BY_ID,
      { variables: { campaignId } }
    ).then(({ data }) => data.getCampaignById);

    if (campaign) {
      Object.assign(props, { campaign: { ...campaign, source } });
      context.setRequestValue('promoCampaignId()', () => campaign.campaignId);
    }
  } catch (error) {
    const log = getServerLogger('_app');
    log.error({ error, query }, 'Error while getting the campaign');
  }

  return props;
};

function KameleoonScript(): React.JSX.Element | null {
  const { locale, location, pageTemplate } = useAppContext<AppContextGlobal>();

  const isKameleoonEnabled = config.get<boolean | undefined>('public.kameleoon.enabled');
  if (!isKameleoonEnabled) return null;

  const isKameleoonSubdomainsScriptEnabled = config.get<boolean | undefined>(
    'public.kameleoon.settings.subdomainScriptEnabled'
  );
  const kameleoonId = config.get<string | undefined>(`public.kameleoon.id.${locale}`) || '';

  const segment = getSegmentFromLocale(locale);

  let domain = '';
  try {
    domain = getDomainFromHostname(location.hostname);
  } catch (error) {
    if (!isBrowser) {
      const log = getServerLogger('_app');
      log.warn({ locale, location, pageTemplate }, error);
    }
    domain = getDomainForLocale(locale);
  }

  const antiFlickerPaths: string[] = get(
    config,
    `public.kameleoon.settings.antiFlicker.pathsByLocale.${locale}`,
    []
  );
  /**
   * Use "/*" to enable the anti flicker across all pages for a locale
   */
  const antiFlickerEnabled =
    config.get('public.kameleoon.settings.antiFlicker.enabled') &&
    (antiFlickerPaths.includes('/*') || antiFlickerPaths.includes(location.pathname));
  const fileName = segment === Segment.Fixter_FR ? 'iframe_FixterFR.html' : 'iframe_FixterUK.html';
  return (
    <>
      {/* Kameleoon anti-flicker script */}
      {antiFlickerEnabled && (
        <Script type="text/javascript" id="kameleoon-af" strategy="beforeInteractive">
          {`
          var kameleoonLoadingTimeout = ${config.get('public.kameleoon.settings.antiFlicker.loadingTimeout')};
           const AFcss = '* { visibility: hidden !important; background-image: none !important; }',
           head = document.head || document.getElementsByTagName('head')[0],
           AFStyle = document.createElement('style');
           head.appendChild(AFStyle);
           AFStyle.type = 'text/css';
           if (AFStyle.styleSheet){
             AFStyle.styleSheet.cssText = AFcss;
           } else {
             AFStyle.appendChild(document.createTextNode(AFcss));
           }
           window.setTimeout(() => {
             head.removeChild(AFStyle);
           }, kameleoonLoadingTimeout);
        `}
        </Script>
      )}

      {/* Kameleoon iframe - needed only on production */}
      {/* Needed to track a user if it's going on a different subdomain - check if this is required on our end */}
      {isKameleoonSubdomainsScriptEnabled && (
        <Script type="text/javascript" id="kameleoon">
          {`
         // Change the value of this URL to point to your own URL, where the iFrame is hosted
        window.kameleoonIframeURL = "https://${domain}/kameleoon/${fileName}"; // PASTE HERE ACTUAL IFRAME URL

        window.kameleoonLightIframe = false;
        var kameleoonIframeOriginElement = document.createElement("a");
        kameleoonIframeOriginElement.href = kameleoonIframeURL;
        window.kameleoonIframeOrigin = kameleoonIframeOriginElement.origin || (kameleoonIframeOriginElement.protocol + "//" + kameleoonIframeOriginElement.hostname);
        if (location.href.indexOf(window.kameleoonIframeOrigin) != 0)
        {
          window.kameleoonLightIframe = true;
          var kameleoonProcessMessageEvent = function(event)
        {
          if (window.kameleoonIframeOrigin == event.origin && event.data.slice && event.data.slice(0,9) == "Kameleoon")
        {
          window.removeEventListener("message", kameleoonProcessMessageEvent);
          window.kameleoonExternalIFrameLoaded = true;
          if (window.Kameleoon)
        {
          Kameleoon.Utils.runProtectedScript(event.data);
          Kameleoon.Analyst.load();
        }
          else
        {
          window.kameleoonExternalIFrameLoadedData = event.data;
        }
        }
        };
          if (window.addEventListener)
        {
          window.addEventListener("message", kameleoonProcessMessageEvent, false);
        }
          var iframeNode = document.createElement("iframe");
          iframeNode.src = kameleoonIframeURL;
          iframeNode.id = "kameleoonExternalIframe";
          iframeNode.style = "float: left !important; opacity: 0.0 !important; width: 0px !important; height: 0px !important;";
          document.head.appendChild(iframeNode);
        }
        `}
        </Script>
      )}

      <Script
        type="text/javascript"
        src={`//${kameleoonId}.kameleoon.eu/kameleoon.js`}
        strategy="beforeInteractive"
        async
      />
    </>
  );
}

function ActiveCampaignForm(): React.ReactNode {
  const { locale, location } = useAppContext<AppContextGlobal>();
  const isEnabled =
    config.get<boolean | undefined>(`public.features.activeCampaignForm.${locale}.enabled`) || false;
  const scriptSrc = config.get<string | undefined>(`public.features.activeCampaignForm.${locale}.src`) || '';
  const isHomepage = location.pathname === '/';
  const abTestActive = useActiveExperiment(ActiveExperiments.ACForm10Off, 'B');

  const shouldLoad = isEnabled && abTestActive && scriptSrc && isHomepage;

  if (!shouldLoad) return null;

  return <Script src={scriptSrc} charSet="utf-8" />;
}

/**
 * App head links and scripts.
 *
 * NOTE: don't include in widget page
 */
function AppHeadContent(): React.JSX.Element {
  const {
    locale,
    location: { hostname },
    cookies,
  } = useAppContext<AppContextGlobal>();

  // We want to display the favicon only when customers come from the membership garage website
  const isFixterGarageMembership = cookies.fixterMembershipGarage && cookies.fixterMembershipGarageDomain;

  let faviconSrc =
    'https://res.cloudinary.com/fxtr/image/upload/v1699978748/landing/favicon-32x32_f6h7xd.png';

  if (isFixterGarageMembership)
    faviconSrc = 'https://res.cloudinary.com/fxtr/image/upload/v1699978748/landing/garage-favicon.png';

  /**
   * @todo this changes the locale globally instead of per request
   * and can have side-effects on concurrent requests with different locales.
   */
  dayjs.locale(locale);

  const oneTrustScriptId = getOneTrustScriptId(hostname);
  const ga4Id = config.get<string | undefined>(`public.ga4.${locale}`) || '';
  const floodlightId = config.get<string | undefined>(`public.tags.floodlight.id.${locale}`) || '';

  return (
    <>
      <Head>
        <link rel="shortcut icon" href={faviconSrc} />
      </Head>

      {/**
       * For modern browsers that are not updated we let them access the page but show a notification banner.
       * @link https://browser-update.org
       *
       * @note The original script uses `DOMContentLoaded` but we changed it to be able to use `lazyOnload` strategy.
       *
       * Internet Explorer will be redirected to our `outdated-browser.html` from server. (express)
       */}
      <Script id="browser-update" strategy="lazyOnload">{`
      var $buoop = {required:{e:-6,f:-6,o:-6,s:-6,c:-6},insecure:true,api:2024.04 };
      function $buo_f(){
       var e = document.createElement("script");
       e.src = "//browser-update.org/update.min.js";
       document.body.appendChild(e);
      };
      $buo_f();
      `}</Script>

      <KameleoonScript />

      {config.get('public.ga4.enabled') && (
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
        `,
          }}
        />
      )}
      <script defer src={`https://www.googletagmanager.com/gtag/js?id=${ga4Id}`} />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          gtag('js', new Date());
          gtag('config', '${ga4Id}', {
            send_page_view: false
          });
          ${floodlightId ? `gtag('config', '${floodlightId}');` : ''}
        `,
        }}
      />
      {config.get('public.oneTrust.enabled') && (
        <>
          <Script
            defer
            src={config.get(`public.oneTrust.scriptUrl`)}
            type="text/javascript"
            data-domain-script={oneTrustScriptId}
            data-document-language="true"
          />
          <Script type="text/javascript" id="ot-callback">{`
            function OptanonWrapper() {
              if (window.OneTrust) {
                window.initializeInternalScripts();
                window.handleGoogleConsent();
                window.OneTrust.OnConsentChanged(window.oneTrustCallback);

              }
            }
          `}</Script>
        </>
      )}
      <ActiveCampaignForm />
    </>
  );
}

/**
 * @todo migrate everything to App Router
 * @deprecated
 */
class LandingApp extends App {
  static async getInitialProps(appContext: AppContextType): Promise<AppInitialProps> {
    const { Component, ctx } = appContext;
    if (!ctx) return { pageProps: {} };
    let pageProps: AppInitialProps['pageProps'] = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    withCookiesPageProps(pageProps, appContext);
    withServerParamsPageProps(pageProps, appContext);
    withPageTemplatePageProps(pageProps, Component);
    withLocationPageProps(pageProps, appContext);
    await withCampaignPageProps(pageProps, appContext);

    return { pageProps };
  }

  componentDidMount() {
    // Prevent any external scripts from loading if page type is 'widget'
    if (this.isWidgetPage) return;
    // @TODO
    // if (config.get('public.features.debugApolloCacheSsr')) debugApolloClientDevToolsSsrCacheData();

    /**
     * @note migrated to `LayoutSetupEffects`
     */
    internalAnalytics.sendAnalyticsGclid();
    internalAnalytics.sendAnalyticsPageView();
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (!config.get('public.sentry.enabled')) return;
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo as unknown as Extras);
      Sentry.captureException(error);
    });
  }

  /**
   * Create the global app context from page props.
   */
  makeAppContext = (): AppContextGlobal => {
    const { pageProps } = this.props;
    const segment = getSegmentFromDomain(pageProps.location.hostname);
    return {
      ...qs.parse(pageProps.serverParams),
      cookies: pageProps.cookies,
      cmsContent: pageProps.cmsContent ?? { dictionary: {} },
      location: pageProps.location,
      locale: getLocale(segment),
      segment,
      campaign: pageProps.campaign,
      pageTemplate: { name: '_app' },
    };
  };

  get isWidgetPage() {
    const { router } = this.props;
    return get(router, 'route') === '/widget';
  }

  render() {
    /**
     * Make sure the generated ids stay in sync CSR&SSR
     */
    resetId();

    const { isWidgetPage, makeAppContext } = this;
    const { Component, pageProps } = this.props;

    /**
     * Use the layout defined at the page level, if available.
     * @see https://nextjs.org/docs/basic-features/layouts#per-page-layouts
     */
    const getLayout =
      (Component as typeof Component & { getLayout: (page: JSX.Element, props: AnyObject) => JSX.Element })
        .getLayout || ((page: JSX.Element) => page);

    return (
      <AppContext.Provider value={makeAppContext()}>
        {!isWidgetPage && <AppHeadContent />}
        {getLayout(<Component {...pageProps} />, pageProps)}
      </AppContext.Provider>
    );
  }
}

export default withRouter(LandingApp);
